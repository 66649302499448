// Dependencies
import React from "react"
// Components
import Layout from "../components/Layout"

const NotFoundPage = () => (
  <Layout title={'404'}>
		<h1>404 Page not found</h1>
  </Layout>
)

export default NotFoundPage
